import request from '@/utils/request'
interface getActiveListType {
  type: number | string
  page: number
  size: number
}

interface settledType {
  applicant: string
  contact: string
  description: string
  inDate: Date
  industry1: string
  industry2: string
  name: string
  personSize: string
  proportion: string
  province: string
  request: string
  siteId: string
  stage: string
}
interface GetIncubateType {
  type: number | string // ?: 可选的
}
interface newNumberType {
  id: string | number
}
interface siteNumberType {
  id: string
}
export const getList = () => {
  return request({
    url: '/operation/api/ns/homepage/main',
    method: 'get'
  })
}

export const getActiveList = (data: getActiveListType) => {
  console.log(data)
  return request({
    url: '/operation/api/ns/homepage/news/page',
    method: 'get',
    params: data
  })
}

export const getDict = (typeCode: string) => {
  return request({
    url: 'uaa/api/dict/value',
    method: 'get',
    params: {
      typeCode
    }
  })
}

export const getDictMultiple = (typeCode: any) => {
  return request({
    url: 'uaa/api/dict/value/multiple',
    method: 'get',
    params: {
      typeCode
    }
  })
}

export const getIncubate = (data: GetIncubateType) => {
  console.log(data)
  return request({
    url: '/operation/api/ns/homepage/incase/list',
    method: 'get',
    params: data
  })
}

export const memorableEvents = () => {
  return request({
    url: '/operation/api/ns/homepage/events/list',
    method: 'get'
  })
}

export const newNumber = (data: newNumberType) => {
  return request({
    url: '/operation/api/ns/homepage/news/detail',
    method: 'get',
    params: data
  })
}

export const sitesList = () => {
  return request({
    url: '/operation/api/ns/homepage/site/list',
    method: 'get'
  })
}
export const siteNumber = (data: siteNumberType) => {
  return request({
    url: '/operation/api/ns/homepage/site/detail',
    method: 'get',
    params: data
  })
}

export const activities = (data: siteNumberType) => {
  return request({
    url: '/operation/api/ns/homepage/activity/detail',
    method: 'get',
    params: data
  })
}

export const settled = (data: any) => {
  return request({
    url: '/operation/api/ns/settled',
    method: 'post',
    data: data
  })
}

export const finance = (data: any) => {
  return request({
    url: '/operation/api/finance',
    method: 'post',
    data: data
  })
}
export const visit = (data: any) => {
  return request({
    url: '/operation/api/ns/visit',
    method: 'post',
    data: data
  })
}
