
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  computed,
  ref,
  toRefs,
  provide,
  watch
} from 'vue'
import GlobalHeader from './components/GlobalHeader.vue'
import GlobalFooter from './components/GlobalFooter.vue'
import GlobalOrder from './components/GlobalOrder.vue'
import GlobalApply from './components/GlobalApply.vue'
import GlobalAmount from './components/GlobalAmount.vue'
import GlobalDrawer from './components/GlobalDrawer.vue'
import GlobalCooperation from './components/GlobalCooperation.vue'
import GlobalDrawerPc from './components/GlobalDrawerPc.vue'
import { MenuProps, useStore } from './store'
import { useRoute } from 'vue-router'
import { sitesList } from '@/api/home'
const { body } = document

const menuData: MenuProps[] = [
  {
    name: '全国站点',
    url: '/home'
  },
  {
    name: '活动·资讯',
    url: '/active'
  },
  {
    name: '孵化案例',
    url: '/example'
  },
  {
    name: '顺融资本',
    url: ''
  },
  {
    name: '关于我们',
    url: '/about'
  }
]

export default defineComponent({
  name: 'App',
  components: {
    GlobalAmount,
    GlobalHeader,
    GlobalFooter,
    GlobalOrder,
    GlobalApply,
    GlobalDrawer,
    GlobalDrawerPc,
    GlobalCooperation
  },
  setup() {
    const store: any = useStore()
    const device = computed(() => store.state.device)
    const orderStatus = ref(false)
    const applyStatus = ref(false)
    const amountStatus = ref(false)
    const drawerStatus = ref(false)
    const preaStatus = ref(false)
    const siteDetail = ref()
    const { mobileWidth } = toRefs(store.state)
    const isMeet = ref(true)
    const route = useRoute() as any
    provide('isH5', device.value === 'mobile')
    provide('orderStatus', orderStatus) // provide的第一个为名称，第二个值为所需要传的参数
    provide('applyStatus', applyStatus)
    provide('amountStatus', amountStatus)
    provide('drawerStatus', drawerStatus)
    provide('preaStatus', preaStatus)
    const {
      changeDrawer: [changeDrawer]
    } = store._mutations
    const isMobile = () => {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < mobileWidth.value
    }
    const sitesListFunc = async () => {
      const data = await sitesList()
      siteDetail.value = data.data
    }
    provide('siteDetail', siteDetail)
    const resizeHandler = () => {
      if (!document.hidden) {
        store.dispatch('toggleDevice', isMobile() ? 'mobile' : 'desktop')
      }
    }
    // 在setup中
    watch(
      () => route.path,
      () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        console.log(route.path)
        if (route.path.includes('meet')) {
          isMeet.value = true
        } else {
          isMeet.value = false
        }
      }
    )
    onBeforeMount(() => {
      window.addEventListener('resize', resizeHandler)
    })
    onMounted(() => {
      if (isMobile()) {
        store.dispatch('toggleDevice', 'mobile')
      }
      sitesListFunc()
    })
    return {
      isMeet,
      list: menuData,
      isMobile,
      resizeHandler,
      device,
      changeDrawer
    }
  }
})
