
import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  inject,
  watch
} from 'vue'

import { MenuProps, useStore } from '../store'
import { useRouter, useRoute } from 'vue-router'
export default defineComponent({
  name: 'GlobalHeader',
  props: {
    menu: {
      type: Array as PropType<MenuProps[]>,
      required: true
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const isOpen = ref(props.device === 'desktop')
    const activeIndex = ref('0')
    const menus = ref(null)
    const show = ref(true)
    const orderStatus = inject('orderStatus')
    const { drawer } = toRefs(store.state)
    const {
      changeDrawer: [changeDrawer]
    } = (store as any)._mutations
    const toggleOpen = () => {
      console.log(isOpen.value)
      isOpen.value = !isOpen.value
    }
    const nextHome = () => {
      router.push('/home')
    }

    const menuOpen = ref(false)

    const handleSelect = (key: number | string) => {
      if (key === '0') {
        changeDrawer(true)
        menuOpen.value = true
      } else {
        router.push(`${props.menu[key as number].url}`)
      }
      if (key === '3') {
        window.open('http://www.shunrongvc.com/')
      }
      if (props.device !== 'desktop') {
        isOpen.value = !isOpen.value
      }
    }

    const handleMouseEnter = (index: number) => {
      if (props.device === 'desktop') {
        if (index === 0) {
          changeDrawer(true)
        } else {
          changeDrawer(false)
        }
        menuOpen.value = !menuOpen.value
      }
    }

    const setActive = () => {
      const idx = props.menu.findIndex(e => {
        // console.log(route.path, e.url)
        return route.path === e.url
      })
      activeIndex.value = idx + ''
    }

    onMounted(() => {
      setActive()
    })

    watch(
      () => route.path,
      () => {
        setActive()
      }
    )

    return {
      orderStatus,
      nextHome,
      show,
      drawer,
      changeDrawer,
      menus,
      handleMouseEnter,
      handleSelect,
      isOpen,
      activeIndex,
      toggleOpen
    }
  }
})
