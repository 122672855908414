<template>
  <footer class="footer">
    <div class="container">
      <div class="main">
        <el-row :gutter="20">
          <el-col :md="10" :sm="10" :xs="24">
            <ul class="link">
              <li @click="$router.push('/about')">关于PGY</li>
              <li @click="$router.push('/example')">孵化生态</li>
              <li @click="localtion('https://www.liepin.com/company/9714434/')">
                加入我们
              </li>
              <li @click="injects">寻求合作</li>
            </ul>
          </el-col>
          <el-col :md="4" :sm="4" :xs="24">
            <img class="qrcode" src="@/assets/qrcode.png" alt="" />
          </el-col>
          <el-col :md="10" :sm="10" :xs="24">
            <ul class="info">
              <li>反馈建议：service@pgy.space</li>
              <li>工作时间：周一到周五 9：00 - 18：00</li>
              <li>总部地址：苏州工业园区人工智能产业园G4栋12楼</li>
              <li>联系我们：0512-67902058</li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="copyright">
        Copyright©2015 苏州创江湖网络科技有限公司 All Rights Reserved
        苏ICP备15024960号-3
      </div>
    </div>
  </footer>
</template>
<script>
import { defineComponent, inject } from 'vue'
export default defineComponent({
  setup() {
    const preaStatus = inject('preaStatus')
    const localtion = type => {
      window.open(type)
    }
    const injects = () => {
      preaStatus.value = true
    }
    return {
      injects,
      localtion,
      preaStatus
    }
  }
})
</script>
<style lang="scss" scoped>
.footer {
  position: relative;
  z-index: 99;
  background-color: #000;
  color: #fff;
  li {
    cursor: pointer;
  }
  .main {
    padding: 50px 0;
    .link {
      line-height: 2;
      display: flex;
      justify-content: space-between;
    }
    .info {
      line-height: 2;
      font-size: 14px;
    }
    .qrcode {
      margin: 10px 0;
    }
  }
  .copyright {
    font-size: 14px;
    padding: 20px 0;
    line-height: 1.4;
    text-align: center;
    border-top: 1px solid #979797;
  }
}

@media (min-width: 768px) {
  .link {
    display: block !important;
    text-align: center;
  }
  .qrcode {
    margin: 0 !important;
  }
}
</style>
