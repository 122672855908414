
import request from '@/utils/request'
interface MeetingRoomType {
  meetingRoomId?: number | string // ?: 可选的
  meetingDateStr?: string // ?: 可选的
  siteId?: number | string
  appId?: number | string
}
interface MeetingRoomInfo {
  appId?: number | string
  bookingPerson: string
  meetingEndTimeStr: string
  meetingName: string
  meetingRoomId: number|string
  meetingStartTimeStr: string
  siteId: string | number
  tel: string | number,
  num: string | number
}

interface AuthProps {
  redirectUri: string
}
interface wxLoginProps {
  code: string
  state: string
}

// interface MeetingType {
//   meetingId: string | number // ?: 可选的
// }
interface cancleObj {
  id: string | number
  siteId ?: string | number
  appId ?: string | number
}
export const getActiveList = () => {
  return request({
    url: '/operation/api/meeting/room/page',
    method: 'get'
  })
}

/**
 * @description: 会议室列表
 */
export const getMeetingRoomList = (data: MeetingRoomType) => {
  return request({
    url: '/operation/api/ns/meeting/room',
    method: 'get',
    params: data
  })
}

/**
 * @description: 查询会议室对应的预约会议数据
 */
export const getMeetingList = (data: MeetingRoomType) => {
  return request({
    url: '/operation/api/ns/meeting/page',
    method: 'get',
    params: data
  })
}

/**
 * @description: 添加会议
 */
export const createMeeting = (data: MeetingRoomInfo) => {
  return request({
    url: '/operation/api/ns/meeting/create',
    method: 'post',
    data: data
  })
}

/**
 * @description: 编辑会议
 */
export const editMeeting = (data: any) => {
  return request({
    url: '/operation/api/ns/meeting/update',
    method: 'post',
    data: data
  })
}

/**
 * @description: 会议详情
 */
export const getMeetingDetail = (data: any) => {
  return request({
    url: '/operation/api/ns/meeting/detail',
    method: 'get',
    params: data
  })
}

/**
 * @description: 取消会议
 */
export const cancelMeeting = (data: cancleObj) => {
  return request({
    url: '/operation/api/ns/meeting/cancel',
    method: 'post',
    data
  })
}

export const wxAuthorize = (data: AuthProps) => {
  return request({
    url: '/uaa/api/ua/mp/authorize-uri',
    method: 'get',
    params: data
  })
}

export const wxLogin = (data: any) => {
  return request({
    url: '/uaa/api/ua/mp/wechat-open',
    method: 'get',
    params: data
  })
}

export const getSiteList = () => {
  return request({
    url: '/operation/api/ns/meetingRoom/site',
    method: 'get'
  })
}

/**
 * @description: 冲突会议
 */
export const conflictMeeting = (params: any) => {
  return request({
    url: '/operation/api/ns/meeting/conflict',
    method: 'get',
    params
  })
}
