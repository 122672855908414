<template>
  <div class="index-pop-wrapper">
    <div class="index-pop-title">
      <h3>提交成功</h3>
      <!---->
      <div class="close"></div>
    </div>
    <!----><!----><!---->
    <div class="form-success">
      <p>
        提交完成后，我们的创业关爱官将尽快与您联系。<br />您也可以直接电话咨询其他需求。
      </p>
      <div class="tel">0512-67902058</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.index-pop-wrapper {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  height: 100%;
  padding-left: 50px;
}
.index-pop-title {
  color: #000;
  position: relative;
  margin-bottom: 50px;
}
.index-pop-title h3 {
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
}
.form-success p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 2;
}
.form-success .tel {
  margin-top: 30px;
  margin-bottom: 100px;
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
</style>
