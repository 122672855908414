import axios from 'axios'
import { ElMessage } from 'element-plus'
// import { UserModule } from '@/store/modules/user'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000
  // withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  config => {
    config.headers.tenantHost = 'oa.pgyspace.com'
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  response => {
    const { status, data } = response
    if (status.toString().startsWith('20')) {
      if (data.returnCode !== '2000') {
        ElMessage({
          message: data.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(data.errorMessage || 'Error'))
      } else {
        return data
      }
    } else {
      return Promise.reject(new Error(data.errorMessage || 'Error'))
    }
  },
  error => {
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
