import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { useRouter } from 'vue-router'
import { store, key } from './store'
import ElementPlus from 'element-plus'
import '@/assets/style/index.scss'
import swiper from '@/plugins/swiper'
import {
  DatetimePicker,
  Popup,
  Cell,
  CellGroup,
  Field,
  Form,
  Picker,
  ActionSheet,
  NavBar,
  Toast
} from 'vant'

// import 'vant/lib/datetime-picker.css'
import 'vant/es/datetime-picker/style'
import 'vant/es/popup/style'
import 'vant/es/cell/style'
import 'vant/es/cell-group/style'
import 'vant/es/form/style'
import 'vant/es/field/style'
import 'vant/es/picker/style'
import 'vant/es/action-sheet/style'
import 'vant/es/nav-bar/style'
import 'vant/es/toast/style'
const app = createApp(App)
const $router = useRouter()
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// 动态注册组件
const requireComponent = require.context('@/components/common', true, /\.vue$/)
const result = requireComponent.keys().filter(item => true)
result.forEach(item => {
  const componentConfig = requireComponent(item)

  const name = item.split('/')[1]
  console.log('componentConfig', name, componentConfig.default)
  app.component(name, componentConfig.default || componentConfig)
})
app.config.globalProperties.$router = $router
app.use(store, key)

app.use(router)
app
  .use(DatetimePicker)
  .use(Popup)
  .use(Cell)
  .use(CellGroup)
  .use(Field)
  .use(Form)
  .use(Picker)
  .use(NavBar)
  .use(ActionSheet)
  .use(Toast)
app.use(ElementPlus)
app.use(swiper)

app.mount('#app')
