
import { ref, defineComponent, onMounted, inject, Ref } from 'vue'
export default defineComponent({
  setup() {
    const preaStatus = inject('preaStatus')
    const handleClose = () => {
      (preaStatus as Ref).value = false
    }
    return {
      handleClose,
      preaStatus
    }
  }
})
