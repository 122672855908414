<template>
  <el-drawer
    v-model="drawer"
    :direction="direction"

    :withHeader="false"
    size="300px"
    modal-class="drawer"
  >
  <!-- :before-close="handleClose" -->
    <div class="list overscroll" style="background-color: #000">
      <div class="list-wrapper flexRow">
        <div class="list-left">
          <h3>孵化器</h3>
          <div class="item" v-for="(item, key) in siteLeft" :key="key">
            <h4>{{ item.name }}</h4>
            <ul>
              <li v-for="e in item.list" :key="e.id">
                <a :href="`/site/?id=${e.id}`">{{ e.siteName }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="list-right">
          <h3>加速器</h3>
          <div class="item" v-for="(item, key) in siteRight" :key="key">
            <h4>{{ item.name }}</h4>
            <ul>
              <li v-for="e in item.list" :key="e.id">
                <a href="">{{ e.siteName }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { useStore } from '../store'
import { defineComponent, onMounted, ref, toRefs, inject } from 'vue'
export default defineComponent({
  setup() {
    const store = useStore()
    const siteLeft = ref([])
    const siteRight = ref([])
    const { drawer } = toRefs(store.state)
    const siteDetail = inject('siteDetail')
    const sitesListFunc = async () => {
      siteDetail.value.map(e => {
        if (e.incubators.length > 0) {
          siteLeft.value.push({
            name: e.provinceStr,
            list: [...e.incubators]
          })
        }
      })
      siteDetail.value.map(e => {
        if (e.boosters.length > 0) {
          siteRight.value.push({
            name: e.provinceStr,
            list: [...e.boosters]
          })
        }
      })
    }
    onMounted(() => {
      setTimeout(() => {
        sitesListFunc()
      }, 1000)
    })

    return {
      siteLeft,
      siteRight,
      direction: ref('ltr'),
      drawer
    }
  }
})
</script>

<style></style>
