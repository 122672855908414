import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "pgy-page",
  class: "app-vue"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_header = _resolveComponent("global-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_global_order = _resolveComponent("global-order")!
  const _component_global_apply = _resolveComponent("global-apply")!
  const _component_global_amount = _resolveComponent("global-amount")!
  const _component_global_drawer = _resolveComponent("global-drawer")!
  const _component_global_drawer_pc = _resolveComponent("global-drawer-pc")!
  const _component_global_cooperation = _resolveComponent("global-cooperation")!
  const _component_global_footer = _resolveComponent("global-footer")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (!_ctx.isMeet)
      ? (_openBlock(), _createBlock(_component_global_header, {
          key: 0,
          menu: _ctx.list,
          device: _ctx.device
        }, null, 8, ["menu", "device"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade-transform",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          _createVNode(_component_router_view)
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(_component_global_order),
    _createVNode(_component_global_apply),
    _createVNode(_component_global_amount),
    _createVNode(_component_global_drawer),
    _createVNode(_component_global_drawer_pc, {
      onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeDrawer(true))),
      onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeDrawer(false)))
    }),
    _createVNode(_component_global_cooperation),
    (!_ctx.isMeet)
      ? (_openBlock(), _createBlock(_component_global_footer, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}