
import {
  defineComponent,
  ref,
  onMounted,
  nextTick,
  computed,
  inject,
  Ref
} from 'vue'
import { getList } from '@/api/home'
import SwiperCore, { Autoplay } from 'swiper/core'
import { useRouter } from 'vue-router'
import { useStore } from '../store'
import moment from 'moment'
SwiperCore.use([Autoplay])

export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()
    const bannerList = ref(null)
    const siteList = ref(null)
    const brandList = ref(null)
    const partnerList = ref(null)
    const slideNum = ref(0)
    const store: any = useStore()
    const device = computed(() => store.state.device)
    const orderStatus = inject('orderStatus') as Ref
    const applyStatus = inject('applyStatus') as Ref
    const amountStatus = inject('amountStatus') as Ref
    // 根据屏幕尺寸展示slide个数
    const resizeHandler = () => {
      if (!document.hidden) {
        const clientWidth = document.body.clientWidth
        if (clientWidth <= 768) {
          slideNum.value = 2
        } else if (clientWidth <= 992) {
          slideNum.value = 3
        } else if (clientWidth <= 1200) {
          slideNum.value = 4
        } else {
          slideNum.value = 5
        }
      }
    }

    const formatValue = computed(() => {
      return (str: string) => {
        return str.replace(/\n/g, '<br>')
      }
    })

    const handleScroll = () => {
      if (device.value === 'mobile') {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        const arr: any = brandList.value
        if (scrollTop < 2000) {
          arr[0].show = true
          arr[1].show = false
          arr[2].show = false
          arr[3].show = false
          arr[4].show = false
        } else if (scrollTop < 2400) {
          arr[0].show = false
          arr[1].show = true
          arr[2].show = false
          arr[3].show = false
          arr[4].show = false
        } else if (scrollTop < 2800) {
          arr[0].show = false
          arr[1].show = false
          arr[2].show = true
          arr[3].show = false
          arr[4].show = false
        } else if (scrollTop < 3200) {
          arr[0].show = false
          arr[1].show = false
          arr[2].show = false
          arr[3].show = true
          arr[4].show = false
        } else {
          arr[0].show = false
          arr[1].show = false
          arr[2].show = false
          arr[3].show = false
          arr[4].show = true
        }
        // brandList.value = arr
      }
    }

    onMounted(async () => {
      const { data } = await getList()
      bannerList.value = data.banners
      siteList.value = data.showSites
      brandList.value = data.events
      partnerList.value = data.partners
      nextTick(() => {
        resizeHandler()
      })
      window.addEventListener('resize', resizeHandler)
      window.addEventListener('scroll', handleScroll)
    })
    const openDialog = (e: string) => {
      if (e === 'order') {
        orderStatus.value = true
      } else if (e === 'apply') {
        applyStatus.value = true
      } else if (e === 'amount') {
        amountStatus.value = true
      }
    }
    const siteDetail = (e: any) => {
      console.log(e)
      router.push({
        path: '/site',
        query: {
          id: e.id
        }
      })
    }
    return {
      siteDetail,
      openDialog,
      device,
      applyStatus,
      orderStatus,
      amountStatus,
      bannerList,
      siteList,
      brandList,
      partnerList,
      slideNum,
      formatValue,
      businessList: ref([
        ['技术验证', '市场验证', '商业验证'],
        ['AI加速营', '创业培训', '技术赋能'],
        ['投融资对接', '大企业对接', '人才对接'],
        ['创业大赛', '行业峰会', '论坛沙龙']
      ])
    }
  }
})
