import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { wxAuthorize, wxLogin } from '@/api/meet'
import Home from '../views/Home.vue'
import Index from '../views/index.vue'
import qs from 'qs'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    meta: {
      title: '蒲公英孵化器-优秀的投资与科创服务孵化平台'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '蒲公英孵化器-优秀的投资与科创服务孵化平台'
    }
  },
  {
    path: '/active',
    name: 'active',
    component: () =>
      import(/* webpackChunkName: "active" */ '../views/active/index.vue'),
    meta: {
      title: '蒲公英孵化器-资讯管理'
    }
  },
  {
    path: '/active/detail',
    name: 'detail',
    component: () =>
      import(
        /* webpackChunkName: "detail" */ '../views/active/detail/index.vue'
      ),
    meta: {
      title: '蒲公英孵化器-资讯详情'
    }
  },
  {
    path: '/example',
    name: 'example',
    component: () =>
      import(/* webpackChunkName: "example" */ '../views/example/index.vue'),
    meta: {
      title: '蒲公英孵化器-孵化案例'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta: {
      title: '蒲公英孵化器-关于我们'
    }
  },
  {
    path: '/site',
    name: 'site',
    component: () =>
      import(/* webpackChunkName: "site" */ '../views/site/index.vue')
  },
  {
    path: '/site/detail',
    name: 'siteDetail',
    component: () =>
      import(
        /* webpackChunkName: "siteDetail" */ '../views/site/detail/index.vue'
      ),
    meta: {
      title: '蒲公英孵化器-站点详情'
    }
  },
  {
    path: '/drawer/amount',
    name: 'drawerAmount',
    component: () =>
      import(/* webpackChunkName: "siteDetail" */ '../views/drawer/amount.vue'),
    meta: {
      title: '申请入驻'
    }
  },
  {
    path: '/drawer/apply',
    name: 'drawerApply',
    component: () =>
      import(/* webpackChunkName: "siteDetail" */ '../views/drawer/apply.vue'),
    meta: {
      title: '蒲公英孵化器-站点详情'
    }
  },
  {
    path: '/drawer/order',
    name: 'drawerOrder',
    component: () =>
      import(/* webpackChunkName: "siteDetail" */ '../views/drawer/order.vue'),
    meta: {
      title: '蒲公英孵化器-站点详情'
    }
  },
  {
    path: '/meet',
    name: 'meet',
    component: () =>
      import(/* webpackChunkName: "meet" */ '../views/meet/index.vue'),
    beforeEnter: async () => {
      const { search } = window.location
      const query = qs.parse(search.startsWith('?') ? search.substr(1) : search)

      if (query.code && query.state) {
        const { data } = await wxLogin({
          code: query.code,
          state: query.state
        })
        localStorage.setItem('openid', data)
      } else {
        const { data } = await wxAuthorize({
          redirectUri: window.location.href
        })
        window.location.href = data
      }
    },
    children: [
      {
        path: 'make-list',
        name: 'makeList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/meet/make-list/index.vue'
          )
      },
      {
        path: 'meet-detail',
        name: 'meetDetail',
        component: () =>
          import(
            /* webpackChunkName: "meetDetail" */ '../views/meet/make-detail/index.vue'
          ),
        meta: {
          title: '蒲公英孵化器-会议详情'
        }
      },
      {
        path: 'meet-list',
        name: 'meetList',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/meet/meet-list/index.vue'
          ),
        meta: {
          title: '蒲公英孵化器-站点列表'
        }
      },
      {
        path: 'make-order',
        name: 'meetOrder',
        component: () =>
          import(
            /* webpackChunkName: "meetOrder" */ '../views/meet/make-order/index.vue'
          ),
        meta: {
          title: '蒲公英孵化器-会议室预定'
        }
      },
      {
        path: 'project-detail',
        name: 'projectDetail',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/meet/project-detail/index.vue'
          ),
        meta: {
          title: '蒲公英孵化器-会议详情'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: any, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
