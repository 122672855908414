<template>
  <div class="app-footer">
    <div class="container">
      <el-row class="app-footer-top">
        <el-col class="app-footer-top-left" :md="12" :sm="24" :xs="24">
          <p>关于PGY</p>
          <p>孵化空间</p>
          <p>加入我们</p>
          <p>寻求合作</p>
        </el-col>
        <el-col class="app-footer-top-right" :md="12" :sm="24" :xs="24">
          <p>反馈建议 / service@pgy.space</p>
          <p>工作时间 / 周一到周五 9：00 - 18：00</p>
          <p>总部地址 / 苏州工业园区创意产业园6栋9楼</p>
          <p>联系我们 / 0512-67902058</p>
        </el-col>
      </el-row>
      <div class="app-footer-bottom"></div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-footer {
  height: 350px;
  color: #fff;
  background: #000;

  &-top {
    margin: 0 auto;
    background: #000;
    height: 272px;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        margin-bottom: 5px;
      }
    }
  }
  &-bottom {
    border-top: 1px solid #979797;
    height: 50px;
  }
}
</style>
