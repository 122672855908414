import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
export interface meetingInfo{
  id: string | number,
  name: string,
  itemid: string
}
export interface State {
  device: string
  drawer: boolean
  drawerPc: boolean
  mobileWidth: number
  siteId: number | string
  currentTime: string,
  selectMeetInfo: meetingInfo,
  openId: string
}
export interface Mutation {
  drawerPc: boolean
}

export const key: InjectionKey<Store<State>> = Symbol('')
export interface ResponseType<P = null> {
  code: number
  msg: string
  data: P
}

export interface MenuProps {
  name: string
  url: string
}

export interface bannerProps {
  name: string
  imgUrl: string
}

export const store = createStore<State>({
  state: {
    device: 'desktop',
    drawer: false,
    drawerPc: false,
    mobileWidth: 992,
    siteId: '',
    currentTime: '',
    selectMeetInfo: {
      id: '',
      name: '',
      itemid: ''
    },
    openId: ''
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    changeDrawer: (state, status) => {
      if (state.device === 'mobile') {
        state.drawer = status
      } else if (state.device === 'desktop') {
        state.drawerPc = status
      } else {
        state.drawer = false
        state.drawerPc = false
      }
    },
    SET_SITEID: (state, siteId) => {
      state.siteId = siteId
    },
    SET_CURRENTTIME: (state, currentTime) => {
      state.currentTime = currentTime
    },
    SET_SELECTMRRTINFO: (state, selectMeetInfo) => {
      state.selectMeetInfo = selectMeetInfo
    },
    SET_OPENID: (state, openId) => {
      localStorage.setItem('openid', openId)
      state.openId = openId
    }
  },
  actions: {
    toggleDevice: ({ commit }, isMobile) => {
      commit('TOGGLE_DEVICE', isMobile)
    }
  },
  modules: {}
})
// 定义自己的 `useStore` 组合式函数
export function useStore() {
  return baseUseStore(key)
}
// export default createStore({

// })
