<template>
  <a href="/home" class="start flexRow"
    ><div class="zimu">
      <span class="zumu-wrapper" id="topText"></span
      ><span class="zumu-wrapper"></span>
    </div>
    <a class="enter"> 为创业者而生<span>&gt;&gt;</span></a></a
  >
</template>

<script>
import { defineComponent, onMounted } from 'vue'
export default defineComponent({
  setup() {
    onMounted(async () => {
      const topText = document.getElementById('topText')
      let i = 0
      let timer = 0
      const topStr = 'ALL FOR STARTUPS'
      function typing() {
        if (i <= topStr.length) {
          topText.innerHTML = topStr.slice(0, i++) + '_'
          timer = setTimeout(typing, 200)
        } else {
          topText.innerHTML = topStr // 结束打字,移除 _ 光标
          clearTimeout(timer)
        }
      }
      typing()
    })
  }
})
</script>

<style lang="scss" scoped>
.start {
  width: 100%;
  height: 100%;
  background: url('../assets/IMG_2794@2x.png');
  background-size: 100%;
  background-color: #ffd00b;
  text-align: left;
  align-items: center;
  position: absolute;
  z-index: 1111;
  top: 0;
  bottom: 0;
  text-decoration: none;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.start .zimu {
  /* width: 1000px; */
  /* height: 400px; */
}
.start .zimu .zumu-wrapper {
  display: block;
  font-size: 150px;
  font-weight: 900;
  color: #fff;
  width: 800px;
}
.start .zimu .zumu-wrapper {
  display: block;
  font-size: 150px;
  font-weight: 900;
  color: #fff;
}
.start .zimu .zumu-wrapper .gb {
  display: inline-block;
  width: 76px;
  height: 16px;
  background: #fff;
  margin-left: 10px;
  /* -webkit-animation: gbTrans 0.8s infinite;
  animation: gbTrans 0.8s infinite; */
}
@keyframes gbTrans {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.start .enter {
  font-size: 30px;
  height: 70px;
  line-height: 70px;
  color: #fff;
  position: absolute;
  right: 80px;
  bottom: 160px;
  letter-spacing: 20px;
}
.start .enter span {
  font-size: 40px;
  font-weight: 700;
}
@media (max-width: 800px) {
  .zumu-wrapper {
    font-size: 40px !important;
  }
  .start {
    padding-top: 100px;
    padding-left: 20px;
    align-items: flex-start;
  }
  .start .enter {
    font-size: 30px;
    line-height: 70px;
    color: #fff;
    position: absolute;
    right: 80px;
    bottom: 160px;
    left: 20px;
    letter-spacing: 20px;
  }
  .start .zimu .zumu-wrapper {
    width: 200px;
  }
}
</style>
