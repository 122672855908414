<template>
  <el-collapse-transition>
    <div class="list  drawer-fixed" v-show="drawerPc">
      <div class="list-wrapper common-flex">
        <div class="list-left">
          <h3>孵化器</h3>
          <div class="item" v-for="(item, key) in siteLeft" :key="key">
            <h4>{{ item.name }}</h4>
            <ul>
              <li v-for="e in item.list" :key="e.id">
                <a v-if="e.siteName" :href="`/site/?id=${e.id}`">{{
                  e.siteName
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="list-right">
          <h3>加速器</h3>
          <div class="item" v-for="(item, key) in siteRight" :key="key">
            <h4>{{ item.name }}</h4>
            <ul>
              <li v-for="e in item.list" :key="e.id">
                <a v-if="e.siteName" :href="`/site/?id=${e.id}`">{{ e.siteName }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </el-collapse-transition>
</template>

<script>
import { defineComponent, onMounted, ref, toRefs, inject } from 'vue'
import { useStore } from '../store'
export default defineComponent({
  setup() {
    const store = useStore()
    const siteLeft = ref([])
    const siteRight = ref([])
    const { drawerPc } = toRefs(store.state)
    const siteDetail = inject('siteDetail')
    console.log('siteDetail', siteDetail)
    const sitesListFunc = async () => {
      siteDetail.value.map(e => {
        if (e.incubators.length > 0) {
          siteLeft.value.push({
            name: e.provinceStr,
            list: [...e.incubators]
          })
        }
      })
      siteDetail.value.map(e => {
        if (e.boosters.length > 0) {
          siteRight.value.push({
            name: e.provinceStr,
            list: [...e.boosters]
          })
        }
      })
    }
    onMounted(() => {
      setTimeout(() => {
        sitesListFunc()
      }, 1000)
    })

    return {
      siteLeft,
      siteRight,
      drawerPc
    }
  }
})
</script>

<style scoped lang="scss">
.drawer-fixed {
  position: absolute;
  top: 70px;
  left: 70%;
  transform: translateX(-50%);
  z-index: 99;
}
.list-left {
  min-width: 220px;
}
.list-right {
  min-width: 220px;
}
</style>
